import moment from "moment";
import React, { useCallback, useEffect, useState } from 'react';
import { withLocalize } from "react-localize-redux";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../Modals/Modal/Modal";
import { addEmployee, removeEmployee, resetEntireState } from "../../Redux/actions/adjustmentActions";
import { resetEntireStateCustom } from '../../Redux/actions/customPayrollActions';
import { changeEmployeeValue } from '../../Redux/actions/dailyListActions';
import types from '../../Redux/actions/types';
import '../../assets/styles/components/Pagination.css';
import '../../assets/styles/components/TimeSheet.css';
import CalculateBillRates from "../../components/common/CalculateBillRates/CalculateBillRates";
import ContainerBlue from "../../components/common/Container/ContainerBlue";
import Datepicker from "../../components/common/Datepicker/Datepicker";
import DeparmentTables from "../../components/common/DepartmentTables/DepartmentTables";
import DropdownList from "../../components/common/Dropdown/DropdownList";
import Loader from '../../components/common/Loader/Loader';
import InfoModal from "../../components/common/Modal/InfoModal";
import OutcomeBar from "../../components/common/OutcomeBar";
import { REACT_APP_URL_CLIENT_LIST_ALL, REACT_APP_URL_GET_ACTIVE_WEEK, REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE } from '../../components/common/Routes/URLs';
import { default as AdjustmentService, default as AdjustmentServices } from '../../services/AdjustmentService';
import TempEdgeApi from '../../services/TempEdgeApi';
import EmployeeList from "../Employee/EmployeeList/EmployeeList";
import AdjustmentTable from "./AdjustmentTable";
import AdjustmentValidation from './AdjustmentValidation';
import AdjustmentSameData from "./AdjustmentSameData";
import TitleMenusDataTables from "../../utils/TitleMenusDataTables";


let DATE_FORMAT_FRONTEND="MM/DD/YYYY"
let DATE_FORMAT_FRONTEND_OLD = "YYYY-MM-DD";
function Adjustment(props) {

	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [clients, setClients] = useState([]);
	const [errors, setErrors] = useState({});
	const [sameData, setSameData] = useState(false)
	const [message, setMessage] = useState(null);
	const [weekActive, setWeekActive] = useState({ start: new Date(), end: null });
	const [departments, setDepartments] = useState([]);
	const [adjustment, setAdjustment] = useState({ company: null, date: null });
	const [endAdjustments, setEndAdjustments] = useState([]);
	const [adjustmentValidations, setAdjustmentValidations] = useState(false)
	const [findWeekisonecompany, setFindWeekisonecompany] = useState(true)

	const [modal, setModal] = useState({
		show: false,
		content: null,
		size: null,
	});

	const initState = () => {
		setClients([])
		setWeekActive({ start: new Date(), end: null })
		setDepartments([])
		setAdjustment({ company: null, date: null })
		setEndAdjustments([])
		setAdjustmentValidations(false)
		setFindWeekisonecompany(true)

	}

	const employees = useSelector(state => {
		return state.adjustmentReducer.employeesInAdjustment;
	});
	const deleteEntiti = useSelector(state => {
		return state.adjustmentReducer.deleteAdjustmentEntitis;
	})


	const selectCompany = useCallback(
		async (value) => {
			setMessage(null)
			try {
				setLoading(true)
				const { data: res } = await TempEdgeApi.post({
					url: REACT_APP_URL_GET_ACTIVE_WEEK,
					payload: {
						clientId: value.clientId
					}
				})
				if (res.status === 200) {
					setFindWeekisonecompany(false)
					setWeekActive({ start: res.result.startDate, end: res.result.endDate })
					setErrors({ ...errors, company: null });
					setAdjustment({ company: value, date: moment(res.result.startDate).subtract(1, 'day').format(DATE_FORMAT_FRONTEND_OLD) });
					mapDepartmentAndPositonList(value.departments);
					weekActiveChange(value.clientId, moment(res.result.startDate).subtract(1, 'day').format(DATE_FORMAT_FRONTEND_OLD))
				}
			} catch (error) {
				setMessage(new Error(error));
			} finally {
				setLoading(false);
			}
		}, [errors])

	const fetchClients = useCallback(async (isMounted) => {
		try {
			setLoading(true);
			const { data: response } = await TempEdgeApi.post({
				url: REACT_APP_URL_CLIENT_LIST_ALL,
				payload: {
					page: 0,
					filterBy: {},
					"parameterKey": "com.tempedge.msg.menu.clientlist"
				}
			});
			if (response.status === 200 && response.code === 'TE00') {
				if (isMounted) {
					setClients(response.result.data.content);
					const data = response.result.data.content;
					if (data.length === 1) {
						setMessage(null)
						mapDepartmentAndPositonList(data[0].departments);
						if (findWeekisonecompany) {
							const { data: res } = await TempEdgeApi.post({
								url: REACT_APP_URL_GET_ACTIVE_WEEK,
								payload: {
									clientId: data[0].clientId
								}
							})
							if (res.status === 200) {
								setFindWeekisonecompany(false)
								setWeekActive({ start: res.result.startDate, end: res.result.endDate })
								setErrors({ ...errors, company: null });
								setAdjustment({ company: data[0], date: moment(res.result.startDate).subtract(1, 'day').format(DATE_FORMAT_FRONTEND) });
								mapDepartmentAndPositonList(data[0].departments);
								weekActiveChange(data[0].clientId, moment(adjustment.date).format(DATE_FORMAT_FRONTEND))
							}
						}
					}
				}
			}
		} catch (error) {
			setMessage(new Error(error));
		} finally {
			setLoading(false);
		}
	},[adjustment.date,errors,findWeekisonecompany])

	useEffect(() => {
		setSameData(false)
		dispatch({
			type: types.CLEAR_PROP,
			payload: "paginatorList",
		});
		let isMounted = true;
		setAdjustmentValidations(false)		
		dispatch(resetEntireState(), resetEntireStateCustom());
		fetchClients(isMounted);
		return () => isMounted = false;
	}, [dispatch, findWeekisonecompany, errors, adjustment,fetchClients]);

	const weekActiveChange = async (client, date) => {
		const { data: weekActive } = await TempEdgeApi.post({
			url: REACT_APP_URL_PAYROLL_FIND_WEEK_ACTIVE,
			payload: {
				"orgId": JSON.parse(localStorage.getItem('agency'))?.organizationEntity?.orgId,
				"clientId": client,
				"dateList": date
			}
		})
		if (weekActive.status === 200) {
			setEndAdjustments(weekActive.result)
		}
	}

	const mapDepartmentAndPositonList = (departments) => {
		const positionAndDepartmentList = [];
		departments?.forEach((department) => {
			department.positions.forEach((position) => {
				positionAndDepartmentList.push({
					positionName: `${department.name} - ${position.name}`,
					positionId: position.positionId,
					pay: position.pay,
					departmentId: department.departmentId
				});
			}
			)
		});
		setDepartments(positionAndDepartmentList);
	}

	async function selectRow(event) {

		const isRowSelected = !!event.currentTarget.classList.value.includes('table-active');
		const cellContent = [];
		event.currentTarget.querySelectorAll('td').forEach((td) => {
			cellContent.push(td.textContent);
		});
		let personId = parseInt(event.currentTarget.dataset.id);

		let adjustmentEntities = [];
		let payrollEntityList = [];
		if (isRowSelected) {
			try {

				if (adjustment.date === null || adjustment.date === undefined) {
					setErrors({ ...errors, date: 'com.tempedge.error.fieldrequired' });
					setModal({ ...modal, show: false });
					return;
				}

				if (adjustment.company === null || adjustment.company === undefined) {
					setErrors({ ...errors, company: 'com.tempedge.error.fieldrequired' });
					setModal({ ...modal, show: false });
					return;
				}

				const response = await AdjustmentServices.findByPerson({
					dateList: adjustment.date,
					clientEntity: {
						clientId: adjustment.company.clientId
					},
					personEntity: {
						personId
					}
				});

				if (response.status === 200 && response.code === 'TE00') {
					const { result } = response;
					adjustmentEntities = result.adjustmentEntities || [];
					payrollEntityList = result.payrollWeekDTOS || [];
				}

			} catch (error) {
				setMessage(new Error(error));
			}

			dispatch(addEmployee({
				id: personId,
				personId: personId,
				employeeId: cellContent[0],
				firstName: cellContent[4],
				lastName: cellContent[3],
				position: null,
				pay: "",
				date: moment(adjustment.date).format(DATE_FORMAT_FRONTEND),
				totalOtHour: 0,
				totalRegHour: 0,
				paymentDate: weekActive.end,
				adjustmentEntities,
				payrollEntityList,
				confirmation: !(adjustmentEntities.length > 0 || payrollEntityList.length > 0),
				errors: {
					hours: false,
					date: false,
					position: false,
				}
			}));
		} else {
			dispatch(removeEmployee(personId));
		}
	}

	function getAdjustmentEntities() {
		let adjustmentEntities = [];

		if (adjustment.company === null && adjustment.date === null)
			throw new Error('com.tempedge.error.fieldsrequired');

		for (let employee of employees) {

			if (!employee.confirmation)
				throw new Error('com.tempedge.error.confirmationhours');

			if (employee.position === null || employee.date === null || (employee.totalRegHour === null && employee.totalOtHour === null) || employee.pay === null)
				throw new Error('com.tempedge.error.fieldsrequired');

			const request = {
				requestDate: employee.date,
				person: {
					personId: employee.personId
				},
				department: {
					departmentId: employee.position.departmentId
				},
				position: {
					positionId: employee.position.positionId
				},
				totalRegHour: employee.totalRegHour,
				totalOtHour: employee.totalOtHour,
				payRate: employee.pay,
				billRate: CalculateBillRates(employee.pay, adjustment.company.commonMarkup),
				otBillRate: CalculateBillRates(employee.pay, adjustment.company.commonOtMarkup, true),
				paymentDate: moment(employee.paymentDate).format(DATE_FORMAT_FRONTEND)
			};
			adjustmentEntities = [...adjustmentEntities, request];
		}
		return adjustmentEntities;
	}

	function validateAdjustmentEntities() {

		for (let employee of employees) {

			if (employee.position === null) {
				dispatch(changeEmployeeValue({
					employeeId: employee.employeeId,
					key: 'errors',
					value: {
						...employee.errors,
						position: true
					},
				}))
			}

			if (employee.date === null) {
				dispatch(changeEmployeeValue({
					employeeId: employee.employeeId,
					key: 'errors',
					value: {
						...employee.errors,
						date: true
					},
				}))
			}
			if (employee.pay === null) {
				dispatch(changeEmployeeValue({
					employeeId: employee.employeeId,
					key: 'errors',
					value: {
						...employee.errors,
						pay: true
					},
				}))
			}

			if (employee.totalRegHour === null && employee.totalOtHour === null) {
				dispatch(changeEmployeeValue({
					employeeId: employee.employeeId,
					key: 'errors',
					value: {
						...employee.errors,
						hours: true
					},
				}))
			}
		}
	}

	async function handleSubmit() {
		try {

			if (deleteEntiti.length > 0) {
				for (const deleteList of deleteEntiti) {
					if (deleteList !== undefined) {
						const res = await AdjustmentService.deleteAdjustment({ id: deleteList.adjustmentId });
						if (res.status !== 200) {
							setMessage(res);
							break; // Detener el bucle si la respuesta no es 200
						}
					}
				}
			}
			setLoading(true);
			validateAdjustmentEntities();
			const adjustmentEntities = getAdjustmentEntities();
			const request = {
				clientEntity: {
					clientId: adjustment.company.clientId
				},
				adjustmentEntities
			}
			const response = await AdjustmentService.saveAdjustment(request);
			setMessage(response);
			if (response.status === 200 && response.code === 'TE00') {
				setAdjustment({ company: clients.length === 1 ? clients[0] : null, date: moment().toDate() });
				dispatch(resetEntireState());
				initState()
			}
			setSameData(false)
		} catch (e) {
			setMessage(new Error(e));
			setSameData(false)
		} finally {
			setLoading(false);
			setSameData(false)
			fetchClients()
		}
	}

	if (loading)
		return <Loader />

	return (
    <ContainerBlue title={"com.tempedge.msg.title.adjustments"}>
      {message && <OutcomeBar response={message} />}
      <div className="search row flex mt-2 align-items-end">
        <div className="form-group col-12 col-lg-3">
          <label className="text-left label-p">
            {props.translate("com.tempedge.msg.label.company")}
          </label>
          {clients.length === 1 && (
            <InfoModal
              value={clients[0].clientName}
              isModal={true}
              onClick={() => {
                setModal({
                  show: true,
                  content: <DeparmentTables clientSelected={clients[0]} />,
                  size: null
                })
              }}
            />
          )}
          {clients.length > 1 && (
            <DropdownList
              data={clients}
              textField={"clientName"}
              valueField={"clientId"}
              value={adjustment.company}
              isInvalid={errors.company ? true : false}
              onChange={(value) => {
                selectCompany(value)
              }}
            />
          )}
        </div>

        <div className={"form-group col-12 col-lg-3"}>
          <label className="text-left label-p">
            {props.translate("com.tempedge.msg.label.date")}
          </label>
          {
            <Datepicker
              customClass={`form-control tempEdge-input-box ${
                errors.date ? "has-error" : ""
              }`}
              selectedValue={
                adjustment.date
                  ? moment(adjustment.date).toDate()
                  : moment(weekActive.start).subtract(1, "day").toDate()
              }
              maxDate={moment(weekActive.start).subtract(1, "day").toDate()}
              onChange={(date) => {
                setErrors({ ...errors, date: null })
                setAdjustment({
                  ...adjustment,
                  date: moment(date).format(DATE_FORMAT_FRONTEND)
                })
                weekActiveChange(
                  adjustment?.company?.clientId,
                  moment(date).format(DATE_FORMAT_FRONTEND)
                )
              }}
              disabled={employees.length > 0 ? true : false}
            />
          }
        </div>
        <div className={"form-group col-12 col-lg-3"}></div>
        <div className="form-group col-12 col-lg-3">
          <button
            type="button"
            onClick={() => {
              setModal({
                show: true,
                content: (
                  <EmployeeList
                    multipleRows
                    selectRow={(event) => {
                      selectRow(event)
                    }}
                    isSelected={(personId) =>
                      employees.find(
                        (employee) => employee.personId === personId
                      )
                    }
                    onClickRows={() => {
                      setModal({ show: false, content: null, size: null })
                    }}
                  />
                ),
                size: "modal-lg"
              })
            }}
            className="btn btn-green w-100"
            disabled={
              adjustment.company !== null && !adjustmentValidations
                ? false
                : true
            }
          >
            {props.translate("com.tempedge.msg.label.selectemployees")}
          </button>
        </div>
      </div>

      {employees && !adjustmentValidations && employees.length > 0 && (
        <div className={"time-sheet"}>
          <div className="">
            <div>
              {sameData && (
                <div className="col-12">
                  <div className="">
                    <div className="py-1 green-text font-weight-bold text-uppercase">
                      {props.translate("com.tempedge.msg.label.samehour")}
                    </div>
                    <AdjustmentSameData
                      departments={departments}
					  weekActive={weekActive}
            		  weekInfo={endAdjustments}
					  selectedDate={adjustment.date}
                    />
                  </div>
                </div>
              )}
              <div
                className={`${
                  sameData ? "time-entry__header col-12 mt-1 " : "col-12"
                }`}
              >
                <TitleMenusDataTables
                  title={"com.tempedge.msg.menu.adjustments"}
                  sameData={sameData}
                  employees={employees}
				  setSameData={setSameData}
                />
              </div>
            </div>
          </div>

          <AdjustmentTable
            weekActiveChange={weekActiveChange}
            departments={departments}
            adjustment={adjustment}
            weekActive={weekActive}
            weekInfo={endAdjustments}
            adjustmentValidations={setAdjustmentValidations}
            employees={employees}
            error={setMessage}
            setFindWeekisonecompany={setFindWeekisonecompany}
            initState={initState}
            setMessage={setMessage}
            deleteEntiti={deleteEntiti}
          />
        </div>
      )}
      {employees && adjustmentValidations && employees.length > 0 && (
        <div className={"time-sheet"}>
          <div className="time-sheet__header">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="form-group col-12 col-lg-3">
                    <p className="green-text font-weight-bold text-uppercase">
                      {props.translate("com.tempedge.msg.menu.adjustments")}
                    </p>
                  </div>
                  <div className="form-group col-12 col-lg-3">
                    <p>
                      {props.translate("com.tempedge.msg.label.listsize")}:
                      <span className="badge">
                        {employees && employees.length}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AdjustmentValidation
            adjustment={adjustment}
            adjustmentValidations={setAdjustmentValidations}
            submit={handleSubmit}
            employees={employees}
            setFindWeekisonecompany={setFindWeekisonecompany}
            initState={initState}
            setMessage={setMessage}
          />
        </div>
      )}
      <Modal
        open={modal.show}
        content={modal.content}
        onClose={() => setModal({ show: false, content: null, size: null })}
        modalSize={modal.size ?? "modal-sm"}
      />
    </ContainerBlue>
  )

}

export default withLocalize(Adjustment);
